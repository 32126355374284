<template>
  <div class="club-banner">
    <a v-if="link" :href="link">
      {{ $t('header.clubBanner') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'ClubBanner',
  data() {
    return {
      link: null,
    }
  },
  mounted() {
    this.link = `${window.location.protocol}//www.${window.location.host.substring(
      window.location.host?.indexOf('.') + 1
    )}`
  },
}
</script>

<style lang="scss" scoped>
.club-banner {
  background-color: var(--night-blue);
  padding: calc(#{$spacing-sm});
  text-align: center;
  font-size: pxToRem(12px);
  font-weight: bold;
  a {
    color: var(--white);
  }
}
</style>
