<template>
  <div :key="`${$i18n.locale}-club`" class="sp-app-container club-layout">
    <div>
      <club-back-mkp-banner />
      <client-only v-if="onStaging || onQA">
        <dev-banner :on-staging="onStaging" />
      </client-only>
      <HeaderSp />
      <div>
        <transition name="fade">
          <div v-show="modals > 0" class="cart-modals">
            <LazyModalsCartConfirmation @modal-show="modals += 1" @modal-hide="modals -= 1" />
          </div>
        </transition>

        <snackbar />
      </div>
      <div class="layout-content">
        <slot />
      </div>
    </div>
    <club-footer />
    <confirmation-modal modal-name="confirmationModalAddToCart">
      <template #title>
        <div class="u-d-f u-c-grapefruit">
          <span class="icon-problem-grapefruit" />
          <b>{{ $t('accreditation.title') }}</b>
        </div>
      </template>
      <template #content>
        <div>
          <strong v-html="$t('accreditation.text')" />
        </div>
      </template>
      <template #confirm-button>
        <main-button
          :label="$t('accreditation.modalConfirmButton')"
          tag="button"
          @click="addToCart(true, stock, quantity, fastAddToCart)"
        />
      </template>
    </confirmation-modal>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useClubStore } from '@/stores/club'
import { useAddToCart } from '@/composables/useAddToCart'

import Snackbar from '@/components/Snackbar/Snackbar'
import ClubFooter from '@/components/Footer/ClubFooter'
import HeaderSp from '@/components/Header/Header'
import DevBanner from '@/components/Banners/DevBanner'
import ClubBackMkpBanner from '@/components/Banners/ClubBackMkpBanner'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'

const { defaultHead, defaultSeo } = useHeadMeta()
useHead(defaultHead())
useSeoMeta(defaultSeo())
const modals = ref(0)
const { stock, quantity, fastAddToCart, addToCart } = useAddToCart()

const config = useRuntimeConfig()
const onStaging = ref(config.public.ENV_NAME === 'STAGING')
const onQA = ref(config.public.ENV_NAME === 'QA')

const { $currentUser, $bus } = useNuxtApp()
const { analytics, events } = useAnalytics()
const store = useStore()
const route = useRoute()
const clubStore = useClubStore()

onBeforeMount(() => {
  analytics.sendEvent(events.LOGIN_STATUS, $currentUser())
  $bus.on('addToCartAction', data => {
    stock.value = data.stock
    quantity.value = data.quantity
    fastAddToCart.value = data.fastAddToCart
    addToCart(data.accepted, stock.value, quantity.value, fastAddToCart.value)
  })
})

onMounted(() => {
  const utms = getUtmTags()
  utms.forEach(utm => {
    store.commit('utm/add', utm)
  })
  const configStore = useConfigStore()
  configStore.add({ showVAT: false })

  const colors = clubStore.clubConfig?.theme?.colors
  if (colors) {
    const { setTheme } = useTheme()
    setTheme({ theme: { colors }, favicon: '/favicon.ico' })
  }
  nextTick(() => {
    if (!window.gtmLoaded) {
      window.gtmLoaded = true
      const gtm = useGtm()
      gtm.enable(true) // Enable GTM dynamically
    }
  })
})

onBeforeUnmount(() => {
  $bus.off('addToCartAction')
})

const getUtmTags = () => {
  const utms = []
  const queryParams = Object.keys(route.query)
  const utmParams = queryParams.filter(key => key.startsWith('utm_'))
  utmParams.forEach(param => {
    utms.push(`${param}:${route.query[param]}`)
  })
  return utms
}
</script>

<style lang="scss">
.club-layout {
  margin: 0 auto !important;
  max-width: $content-max-width;
}

.topbar {
  position: sticky;
  top: 0;

  display: flex;
  align-items: flex-start;

  z-index: 4;

  @include mq($mq-md) {
    display: inherit;
  }
}

.layout-content {
  position: relative;
  width: calc(100% - 100px - $spacing-md);
  left: 100px;

  @include mq($mq-sm) {
    width: 100%;
    left: 0;
    padding-right: 0;
  }
}

.cart-modals {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
  overflow: visible;
  padding-top: $header-padding-v;

  @include mq($mq-sm) {
    padding-top: $header-padding-v-sm;
  }

  * {
    pointer-events: auto;
  }

  .cart-modal-wrapper {
    width: 100%;
    max-width: 345px;
  }

  .cart-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  .cart-modal {
    position: relative;
    padding: 15px;
    background-color: var(--white);
    border-radius: $border-radius-boxes;
    z-index: 2;
    margin: 0 0 20px 0;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);

    .cart-modal-btn-wrapper {
      text-align: right;

      .cart-modal-close {
        text-align: right;
        display: inline-block;
        height: 28px;
        width: 28px;
        background-image: url('@/assets/icons/ico-close-popup.svg');
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: transform var(--transition-duration) var(--transition-timing-function);

        &:hover {
          transform: rotate(-90deg);
        }
      }
    }

    .cart-modal-content {
      .btn {
        margin-top: 15px;
      }
    }
  }
}
</style>
